export const DocumentEndPoint = (build) => ({
  getSystemFolders: build.query({
    query: (arg) => ({
      url: `document/get-system-folders`,
      params: { ...arg }
    }),
    // providesTags: ["SystemFolders"]
  }),
  getFiles: build.query({
    query: (arg) => ({
      url: `document/get-files`,
      params: { ...arg }
    }),
    providesTags: ["Files"]
  }),
  createUserFolder: build.mutation({
    query: (body) => ({
      url: "document/create-user-folder",
      method: "POST",
      body: body
    }),
    invalidatesTags: ["UserFolders", "Storage"]
  }),
  getUserFolders: build.query({
    query: (arg) => ({
      url: "document/get-user-folders",
      params: { ...arg }
    }),
    providesTags: ["UserFolders"]
  }),
  uploadFile: build.mutation({
    query: (body) => ({
      url: "document/upload-file",
      method: "POST",
      body: body
    }),
    invalidatesTags: ["UserFolders", "Storage"]
  }),
  deleteFileAndFolder: build.mutation({
    query: (body) => ({
      url: "document/delete",
      method: "PUT",
      body: body
    }),
    invalidatesTags: ["UserFolders", "Files", "DeletedFilesAndFolders"]
  }),
  restoreFileAndFolder: build.mutation({
    query: (body) => ({
      url: "document/restore",
      method: "PUT",
      body: body
      }),
    invalidatesTags: ["UserFolders", "DeletedFilesAndFolders", "Files"]
  }),
  getDeletedFilesAndFolders: build.query({
    query: (arg) => ({
      url: "document/bin",
      params: { ...arg }
    }),
    providesTags: ["DeletedFilesAndFolders"]
  }),
  emptyBin: build.mutation({
    query: (body) => ({
      url: "document/empty-bin",
      method: "DELETE",
      body: body
    }),
    invalidatesTags: ["DeletedFilesAndFolders", "Storage"]
  }),
  permanentDeleteFilesAndFolders: build.mutation({
    query: (body) => ({
      url: "document/permanent-delete",
      method: "PUT",
      body: body
    }),
    invalidatesTags: ["DeletedFilesAndFolders", "Storage"]
  }),
  getStorage: build.query({
    query: (arg) => ({
      url: "document/storage",
      params: { ...arg }
    }),
    providesTags: ["Storage"]
  })
});
