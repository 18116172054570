import React, { useEffect, useState } from "react";
import "./MyReportList.scss";
import ToggleButton from "../../../components/Buttons/ToggleButton/ToggleButton";
import { Select, Dropdown, Menu, Input, Tooltip } from "antd";
import { Table, TableBody, TableCell, TableContainer, TableHead, Pagination, TableRow, Stack } from "@mui/material";
import { Tags } from "../../../components/Tags/Tags";
import {
  useLazyGetAllReportsQuery,
  useGetReportTypeQuery,
  usePostFilteredReportMutation,
  useDeleteReportMutation,
  usePutloackUnlockReportMutation,
  useLazyGetUserDetailByIdQuery,
  useLazyGetDownLoadReportQuery,
  useLazyGetDownLoadQuery
} from "../../../state/api";
import WidgetLoader from "../../../components/Loaders/WidgetLoader";
import dayjs from "dayjs";
import { capitalizeFirstLetter } from "../../../helper/Utils/utilitiyFunctions";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../../components/Modal/Modal";
import { useSnackbar } from "../../../components/snackbar/SnackbarContext";
import { useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { apiUrl } from "../../../components/ApiUrl";
import ButtonLoader from "../../../components/Loaders/ButtonLoader";

const tableHeader = [
  "Framework",
  "Report name",
  "Reporting period",
  "Created by",
  "Created on",
  "Due date",
  // "Completion date",
  "Status",
  " ",
  " "
];

const MyReportList = () => {
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const [activeButton, setActiveButton] = useState("current");
  const [getAllReports, resultAllReports] = useLazyGetAllReportsQuery();
  const [deleteReports] = useDeleteReportMutation();
  const [putLockUnlockReport, resultLockUnlockReport] = usePutloackUnlockReportMutation();
  const postPerPage = 10;
  const [currentPage, SetCurrentPage] = useState(0);
  const [allReports, setallReports] = useState();
  const [activeDots, setActiveDots] = useState();
  const [reportingFramework, setReportingFramework] = useState("");
  const reportTypeData = useGetReportTypeQuery();
  const [allReportsType, setAllReportsType] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [lockUnlockModel, setLockUnlockModelOpen] = useState(false);
  const [rowWiseReportInfo, setRowWiseReportInfo] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [loadingReports, setLoadingReports] = useState(false);
  const [reportId, setReportId] = useState();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoading, setIsLoading] = useState({ id: "", loading: false });
  const [reportType, setReportType] = useState();

  const [searchTerm, setSearchTerm] = useState("");
  const userId = useSelector((state) => state.global.user);
  const [user, setUser] = useState(null);
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();
  const [downloadModal, setDownLoadModal] = useState(false);
  const [fileType, setFileType] = useState();
  const [yearSelected, setYearSelected] = useState({ financialYear: true, calenderYear: false });

  useEffect(() => {
    getUser({ id: userId._id }, true);
  }, []);

  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data);
    }
  }, [userResult.data?.data]);

  useEffect(() => {
    setAllReportsType(reportTypeData.data?.data);
  }, [reportTypeData.data]);

  useEffect(() => {
    const getReport = async () => {
      setLoadingReports(true);
      try {
        await getAllReports(
          {
            isLocked: activeButton === "current" ? false : true,
            from: `${parseInt(currentPage) - 1 < 0 ? 0 : parseInt(currentPage) - 1}`,
            size: postPerPage
          },
          true
        );
        setLoadingReports(false);
      } catch (error) {
        console.error(error);
        setLoadingReports(false);
      }
    };
    getReport();
  }, [activeButton]);

  useEffect(() => {
    if (resultAllReports?.data?.data) {
      setallReports(resultAllReports?.data);
    }
  }, [resultAllReports?.data?.data]);

  const createReport = () => {
    navigate(`/allreports/create?report`, {
      state: { report: "brsr" }
    });
  };

  const handleButtonClick = (type) => {
    setActiveButton(type);
  };

  const getPayLoad = (pageNo) => {
    let body = {
      isLocked: activeButton === "current" ? false : true,
      from: `${parseInt(pageNo) - 1 < 0 ? 0 : parseInt(pageNo) - 1}`,
      size: postPerPage
    };
    return body;
  };

  const handlePageChange = async (e, value) => {
    if (value - 1 < 0) {
      SetCurrentPage(0);
    } else {
      SetCurrentPage((value - 1) * postPerPage);
    }
    try {
      if (value - 1 < 0) {
        await getAllReports(getPayLoad(0), true);
      } else {
        await getAllReports(getPayLoad((value - 1) * postPerPage), true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function getReportTypeById(id) {
    const report = allReportsType.find((report) => report._id === id);
    return report ? report.reportType : null;
  }

  const handleCreateReport = () => {
    navigate(`/allreports/create?report=${getReportTypeById(reportingFramework)}`, {
      state: { report: "brsr", reportID: reportingFramework }
    });
  };

  const filterBody = {
    isLocked: activeButton === "current" ? false : true,
    from: `${parseInt(currentPage) - 1 < 0 ? 0 : parseInt(currentPage) - 1}`,
    size: postPerPage
  };

  const handleReportFilterChange = (val) => {
    if (val === "") {
      getAllReports(
        {
          ...filterBody
        },
        true
      );
      setFilterApplied(false);
    } else {
      getAllReports(
        {
          reportType: val,
          ...filterBody
        },
        true
      );
      setFilterApplied(true);
    }
  };

  const handleReportStatusChange = (val) => {
    if (val === "all") {
      getAllReports(
        {
          ...filterBody
        },
        true
      );
      setFilterApplied(false);
    } else {
      getAllReports(
        {
          status: val,
          ...filterBody
        },
        true
      );
      setFilterApplied(true);
    }
  };

  const handleYearSelected = (val) => {
    if (val === "all") {
      getAllReports(
        {
          ...filterBody
        },
        true
      );
      setFilterApplied(false);
    } else {
      getAllReports(
        {
          year: val,
          ...filterBody
        },
        true
      );
      setFilterApplied(true);
    }
  };

  const onSearch = (search) => {
    if (!search) {
      getAllReports({ ...filterBody }, true);
      setFilterApplied(false);
    } else {
      getAllReports(
        {
          search: search,
          ...filterBody
        },
        true
      );
      setFilterApplied(true);
    }
  };
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    onSearch(value);
  };

  const clearSearch = () => {
    setSearchTerm("");
    onSearch("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSearch(searchTerm);
    }
  };

  const openDropDown = (item, ind) => {
    setActiveDots(ind);
    setRowWiseReportInfo(item);
  };

  const downloadReport = async (reportId) => {
    try {
      setIsLoading({ id: reportId, loading: true });

      const res = await fetch(`${apiUrl}/report/download?id=${reportId}`, {
        method: "GET",
        credentials: "include"
      });

      // const contentLength = Number(res.headers.get("Content-Length"));
      const contentLength = 52428800;
      const reader = res.body.getReader();

      let receivedLength = 0;
      let chunks = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          // downloadProgressUpdate(0)
          setIsLoading(false);
          break;
        }

        chunks.push(value);
        // receivedLength += value.length;
        // const progress = (receivedLength / contentLength) * 100;
        // downloadProgressUpdate(progress);
      }

      const contentDispositionHeader = res.headers.get("Content-Disposition");
      let fileName = contentDispositionHeader.split("filename=")[1]?.replace(/['"]/g, "");
      if (!fileName) fileName = "GHG_Report.pdf";
      const blob = new Blob(chunks);
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = fileName;
      a.click();

      URL.revokeObjectURL(downloadUrl);

      setIsLoading({ id: reportId, loading: false });
    } catch (error) {
      // setDownloadProgress(0)
      openSnackbar(error.message, "error");
      setIsLoading({ id: reportId, loading: false });
    }
  };

  const handleMenuClick = (e, ind) => {
    setReportId(ind);

    const report = resultAllReports?.data?.data?.reportList.find((report) => report._id === ind);
    const reportType = resultAllReports?.data?.data?.reportList.find((report) => report._id === ind)?.reportType;

    setReportType(reportType);

    if (e.key === "1") {
      if (report?.isPreviousStructureReport === false) {
        setDownLoadModal(true);
      } else {
        // getDownload({ id: report._id });
        downloadReport(report._id);
      }
    } else if (e.key === "2") {
      setLockUnlockModelOpen(true);
    } else if (e.key === "3") {
      setDeleteModalOpen(true);
    }
  };

  const menu = (ind) => (
    <Menu onClick={(e) => handleMenuClick(e, ind)}>
      <Menu.Item key="1" icon={<img src="/images/BRSR/download.svg" alt="icon" />}>
        Download report
      </Menu.Item>
      {rowWiseReportInfo?.status === "completed" ? (
        activeButton === "current" ? (
          <Menu.Item key="2" icon={<img src="/images/BRSR/publish-icon.svg" alt="icon" />}>
            Publish report
          </Menu.Item>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      <Menu.Item key="3" icon={<img src="/images/BRSR/trash-icon.svg" alt="icon" />}>
        Delete report
      </Menu.Item>
    </Menu>
  );

  const confirmDelete = async () => {
    const res = await deleteReports(rowWiseReportInfo._id);
    if (res.data.success) {
      openSnackbar(res?.data?.message, "success");
      setDeleteModalOpen(false);
    } else {
      openSnackbar(res?.data?.message, "warning");
      setDeleteModalOpen(false);
    }
  };

  const confirmLock = async () => {
    let postBody = { id: rowWiseReportInfo._id, isLocked: activeButton === "past" ? false : true };
    try {
      const res = await putLockUnlockReport(postBody);
      if (res.data.success) {
        openSnackbar(res?.data?.message, "success");
        setLockUnlockModelOpen(false);
      } else {
        openSnackbar(res?.data?.message, "warning");
        setLockUnlockModelOpen(false);
      }
    } catch (error) {
      console.log(error);
      setLockUnlockModelOpen(false);
    }
  };

  const moveToDashboard = (item) => {
    navigate("/admin", { state: { data: item } });
  };

  const handleFileTypeChange = (e) => {
    setFileType(e.target.value);
  };

  // const downloadPdfReport = async (reportId) => {
  //   try {
  //     setIsLoading({ id: reportId, loading: true })
  //     const res = await fetchData(
  //       `${apiUrl}/report/download?id=${reportId}`,
  //       {
  //         method: "GET",
  //         credentials: "include",
  //       }
  //     );

  //     // const contentLength = Number(res.headers.get("Content-Length"));
  //     const contentLength = 52428800;
  //     const reader = res.body.getReader();

  //     let receivedLength = 0;
  //     let chunks = [];

  //     while (true) {
  //       const { done, value } = await reader.read();
  //       if (done) {
  //         downloadProgressUpdate(0)
  //         setIsLoading(false)
  //         break;
  //       }

  //       chunks.push(value);
  //       receivedLength += value.length;
  //       const progress = (receivedLength / contentLength) * 100;
  //       downloadProgressUpdate(progress);
  //     }

  //     const contentDispositionHeader = res.headers.get('Content-Disposition');
  //     let fileName = contentDispositionHeader.split("filename=")[1]?.replace(/['"]/g, '');
  //     if (!fileName) fileName = "GHG_Report.pdf";
  //     const blob = new Blob(chunks);
  //     const downloadUrl = URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = downloadUrl;
  //     a.download = fileName;
  //     a.click();

  //     URL.revokeObjectURL(downloadUrl);
  //     postAuditLogData({
  //       action: "DOWNLOAD",
  //       message: `${reports['reportType']} for year ${reports['fyear']} download by ${user.name}`,
  //       tab: "Reports",
  //       facility: "",
  //       organization: orgData.data?.data._id,
  //       user: userId._id,
  //       ipAddress: "10.10.0.1"
  //     })

  //     setIsLoading({ id: reportId, loading: false })
  //   } catch (error) {
  //     setDownloadProgress(0)
  //     openSnackbar(error.message, "error");
  //     setIsLoading({ id: reportId, loading: false })
  //   }
  // }

  const downloadReportHandle = async () => {
    setIsDownloading(true);
    try {
      const apiurl =
        fileType === "Word"
          ? `${apiUrl}/reports/preview/downloadWord?reportId=${reportId}`
          : fileType === "PDF"
          ? `${apiUrl}/reports/preview/downloadPdf?reportId=${reportId}`
          : `${apiUrl}/reports/preview/generateXML?reportId=${reportId}`;

      const response = await fetch(apiurl, {
        method: "GET",
        credentials: "include"
      });

      if (!response.ok) {
        throw new Error("Failed to retrieve document.");
      }

      // Check the response type
      const contentType = response.headers.get("content-type");
      if (!contentType.includes("application/pdf") && fileType === "PDF") {
        throw new Error("The response is not a PDF document.");
      }
      if (
        !contentType.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document") &&
        fileType === "Word"
      ) {
        throw new Error("The response is not a Word document.");
      }

      // Convert to a Blob
      const blob = await response.blob();

      // Define the filename based on fileType
      const filename = fileType === "Word" ? "BRSR_Generated_Document.docx" : fileType === "PDF" ? "BRSR_Generated_Document.pdf" : "BRSR_Generated_Document.xml";

      // Create a download link and trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setIsDownloading(false);
      setDownLoadModal(false);
    } catch (error) {
      setIsDownloading(false);
      setDownLoadModal(false);
      console.error("Error downloading the document:", error);
      // Optionally provide user feedback here
    }
  };

  const timeAgo = (date) => {
    const now = new Date();
    const commentDate = new Date(date);
    const seconds = Math.floor((now - commentDate) / 1000);

    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) return `${interval} year${interval > 1 ? "s" : ""} ago`;
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) return `${interval} month${interval > 1 ? "s" : ""} ago`;
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) return `${interval} day${interval > 1 ? "s" : ""} ago`;
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) return `${interval} hour${interval > 1 ? "s" : ""} ago`;
    interval = Math.floor(seconds / 60);
    if (interval >= 1) return `${interval} minute${interval > 1 ? "s" : ""} ago`;
    return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
  };

  const calculateDaysDifference = (dueDate) => {
    const currentDate = dayjs();
    const due = dayjs(dueDate);

    const diff = due.diff(currentDate, "day");

    if (diff > 0) {
      return `${diff} days left`;
    } else if (diff === 0) {
      return "Today";
    } else {
      return `${Math.abs(diff)} days ago`;
    }
  };

  const financialYear = [
    { value: "all", label: "All Period" },
    { value: "2024-2025", label: "2024-2025" },
    { value: "2023-2024", label: "2023-2024" },
    { value: "2022-2023", label: "2022-2023" },
    { value: "2021-2022", label: "2021-2022" }
  ];

  const calendarYear = [
    { value: "all", label: "All Period" },
    { value: "2025", label: "2025" },
    { value: "2024", label: "2024" },
    { value: "2023", label: "2023" },
    { value: "2022", label: "2022" },
    { value: "2021", label: "2021" }
  ];

  const tableHeader = [
    "Framework",
    "Report name",
    "Reporting period",
    "Created by",
    "Created on",
    "Due date",
    ...(user?.reportRole === "Admin" ? ["Completion Date"] : []),
    "Status",
    " ",
    " "
  ];

  return (
    <>
      <CustomModal modalOpen={downloadModal} setModalOpen={setDownLoadModal} outSideClose={false}>
        <div className="d-flex gap-3">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <circle opacity="0.1" cx="16" cy="16" r="16" fill="#1F93EF" />
              <image href="/images/BRSR/download-modal.svg" x="8" y="8" width="16" height="16" />
            </svg>
          </div>
          <div>
            <div>
              <p className="text-start subtitle-1" style={{ fontSize: "16px" }}>
                Download report
              </p>
              <p className="body-2-Regular">
                Report name : {rowWiseReportInfo?.name} {rowWiseReportInfo?.fyear}
              </p>
            </div>
            <div className="mt-3">
              <p className="subtitle-2">Select file format</p>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={handleFileTypeChange}
                  value={fileType}
                >
                  <FormControlLabel value="PDF" control={<Radio />} label="PDF" />
                  <FormControlLabel value="Word" control={<Radio />} label="Word" />
                  {reportType !== "esg" && <FormControlLabel value="XBRL" control={<Radio />} label="XBRL" />}
                </RadioGroup>
              </FormControl>
              {/* <p className="caption-1-Regular">Last download: Jio BRSR.PDF, 09 Jun 2024, 14:20</p> */}
              {/* <p className="download-again-button">Download again</p> */}
            </div>

            <div className="d-flex gap-4 mt-3">
              <button type="button" className="btn btn-outline-success " onClick={() => setDownLoadModal(false)}>
                Cancel
              </button>
              <button type="button" className="btn btn-success" onClick={downloadReportHandle} disabled={!fileType}>
                {isDownloading ? <ButtonLoader /> : "Download"}
              </button>
            </div>
          </div>
          <div className="d-flex flex-top h-100" style={{ marginTop: "-15px" }}>
            <img
              src="/images/BRSR/modal-Close.svg"
              alt="icon"
              width={28}
              className="cursor-pointer"
              onClick={() => setDownLoadModal(false)}
            />
          </div>
        </div>
      </CustomModal>

      <CustomModal modalOpen={lockUnlockModel} setModalOpen={setLockUnlockModelOpen} outSideClose={false}>
        <div className="d-flex gap-2">
          <div>
            <img src="/images/BRSR/color-info-icon.svg" alt="" />
          </div>
          <div style={{ maxWidth: "390px" }}>
            <div>
              <p className="modal-title-plan text-start" style={{ fontSize: "16px" }}>
                Are you sure you want to {activeButton === "past" ? "unpublish" : "publish"} this report?
              </p>
              <p className="sub-heading-text" style={{ fontSize: "14px" }}>
                This report will not be editable if proceeds, and all contributors will be notified. It will be moved to
                the ‘Past reports’ tab.
              </p>
            </div>

            <div className="d-flex gap-4 mt-3">
              <button type="button" className="btn btn-outline-success " onClick={() => setLockUnlockModelOpen(false)}>
                Cancel
              </button>
              <button type="button" className="btn btn-success" onClick={confirmLock}>
                {activeButton === "past" ? "Unpublish report" : "Publish report"}
              </button>
            </div>
          </div>
        </div>
      </CustomModal>

      <CustomModal modalOpen={deleteModalOpen} setModalOpen={setDeleteModalOpen} outSideClose={false}>
        <div className="d-flex gap-2">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <circle opacity="0.1" cx="16" cy="16" r="16" fill="#CE7B00" />
              <image href="/images/BRSR/trash-modal.svg" x="8" y="8" width="16" height="16" />
            </svg>
          </div>

          <div style={{ maxWidth: "390px" }}>
            <div>
              <p className="modal-title-plan text-start  subtitle-1 mb-0" style={{ fontSize: "16px" }}>
                Delete report?
              </p>
              <p className="sub-heading-text mt-1" style={{ fontSize: "14px" }}>
                Are you sure you want to delete this report?
              </p>
            </div>

            <div className="d-flex gap-4 mt-3">
              <button type="button" className="btn btn-outline-success " onClick={() => setDeleteModalOpen(false)}>
                Cancel
              </button>
              <button type="button" className="btn btn-success" onClick={confirmDelete}>
                Delete report
              </button>
            </div>
          </div>
          <div className="d-flex flex-top h-100" style={{ marginTop: "-15px" }}>
            <img
              src="/images/BRSR/modal-Close.svg"
              alt="icon"
              width={32}
              className="cursor-pointer"
              onClick={() => setDeleteModalOpen(false)}
            />
          </div>
        </div>
      </CustomModal>

      <div className="report-list-mainwrapper">
        <div className="top-wrapper d-flex justify-content-between align-items-center">
          <div>
            <h6 className="report-list-title">My Reports</h6>
          </div>
          {user?.reportRole === "Admin" ? (
            <div>
              <button type="button" className="btn btn-success btn-sm" onClick={createReport}>
                {"Create report >"}
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="toggle-buttons mt-4">
          <ToggleButton
            text="Current reports"
            isActive={activeButton === "current"}
            onClick={() => handleButtonClick("current")}
          />
          <ToggleButton
            text="Past reports"
            isActive={activeButton === "past"}
            onClick={() => handleButtonClick("past")}
          />
        </div>
        <div className="report-table-wrapper mt-3">
          {/* {resultAllReports?.data?.data?.totalCount > 0 && ( */}
            <div className="d-flex justify-content-between select-box">
              <div className="d-flex gap-3">
                <Select
                  placeholder="Report type"
                  variant="filled"
                  style={{ flex: 1, backgroundColor: "#F9F9F9", width: "150px" }}
                  className="custom-select"
                  onChange={(val) => handleReportFilterChange(val)}
                >
                  <Select.Option value={""}>All Types</Select.Option>
                  {allReportsType?.map((item, ind) => (
                    <Select.Option key={ind + "type"} value={item._id}>
                      {item.reportType.toUpperCase()}
                    </Select.Option>
                  ))}
                </Select>

                {activeButton === "current" ? (
                  <Select
                    placeholder="Report status"
                    variant="filled"
                    className="custom-select"
                    style={{ flex: 1, backgroundColor: "#F9F9F9", width: "150px" }}
                    onChange={(val) => handleReportStatusChange(val)}
                  >
                    <Select.Option value={"all"}>All status</Select.Option>
                    <Select.Option value={"pending"}>Pending</Select.Option>
                    <Select.Option value={"overdue"}>OverDue</Select.Option>
                    <Select.Option value={"completed"}>Completed</Select.Option>
                  </Select>
                ) : (
                  ""
                )}
                <Select
                  // value={` ${yearSelected.calenderYear ? "" : "FY"} ${yearData}`}
                  placeholder="Reporting period"
                  variant="filled"
                  className="custom-select"
                  style={{ backgroundColor: "#F9F9F9", width: "250px" }}
                  onChange={(newValue) => handleYearSelected(newValue)}
                  dropdownRender={(menu) => (
                    <div className="dropdown-reporting-year">
                      <div className="year-select-container d-flex">
                        <div
                          className={`year-select ${yearSelected.financialYear ? "active-year" : ""}`}
                          onClick={() => setYearSelected({ financialYear: true, calenderYear: false })}
                        >
                          <p>Financial Year</p>
                        </div>
                        <div
                          className={`year-select ${yearSelected.calenderYear ? "active-year" : ""}`}
                          onClick={() => setYearSelected({ financialYear: false, calenderYear: true })}
                        >
                          <p>Calendar Year</p>
                        </div>
                      </div>
                      {menu}
                    </div>
                  )}
                >
                  {yearSelected.financialYear
                    ? financialYear?.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))
                    : calendarYear?.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                </Select>
                {/* <Select
                  placeholder="Reporting period"
                  variant="filled"
                  className="custom-select"
                  style={{ flex: 1, backgroundColor: "#F9F9F9", width: "200px" }}
                  onChange={(newValue) => handleYearSelected(newValue)}
                >
                  <Select.Option value={"all"}>All period</Select.Option>
                  <Select.Option value={"2024-2025"}>2024-2025</Select.Option>
                  <Select.Option value={"2023-2024"}>2023-2024</Select.Option>
                  <Select.Option value={"2022-2023"}>2022-2023</Select.Option>
                  <Select.Option value={"2021-2022"}>2021-2022</Select.Option>
                </Select> */}
              </div>
              <div>
                <Input
                  size="meduim"
                  value={searchTerm}
                  placeholder={`Search report name`}
                  suffix={
                    searchTerm !== "" ? (
                      <CloseCircleOutlined onClick={clearSearch} style={{ cursor: "pointer", color: "#1890ff" }} />
                    ) : (
                      <img src="images/icons/search-icon.svg" alt="icon" />
                    )
                  }
                  onChange={handleSearchChange}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>
          {/* )} */}

          {resultAllReports.isLoading || loadingReports ? (
            <div className="empty-wrapper">
              <WidgetLoader />
            </div>
          ) : allReports?.data.totalCount === 0 ? (
            <div
              className="d-flex flex-column align-items-center justify-content-center text-center w-100 p-"
              style={{ height: "60vh", paddingTop: "50px", paddingBottom: "50px" }}
            >
              <img src="images/BRSR/empty-report.svg" alt="info-icon" className="mb-2" />
              <h6 className="heading-text mb-2">
                No{" "}
                {activeButton === "past"
                  ? "past report found."
                  : filterApplied
                  ? "reports found."
                  : "reports created yet."}
              </h6>
              {!filterApplied && activeButton === "current" && (
                <>
                  <p className="sub-heading-text mb-3">Select Reporting framework and create new report.</p>
                  {user?.reportRole === "Admin" && (
                    <div>
                      <Select
                        placeholder="Select a framework"
                        size="large"
                        value={reportingFramework}
                        onChange={(value) => setReportingFramework(value)}
                        className="dropdown-antd"
                        style={{ width: "300px" }}
                      >
                        {allReportsType?.map((item, ind) => (
                          <Select.Option key={item._id + ind} value={item._id} disabled={!item.isActive}>
                            {item.header}
                          </Select.Option>
                        ))}
                      </Select>

                      <div className="mt-4">
                        <button
                          type="button"
                          className={`btn rounded-2${reportingFramework !== "" ? " btn btn-success" : ""}`}
                          style={{ backgroundColor: "#EBF6FF", color: "#16161E52" }}
                          onClick={handleCreateReport}
                        >
                          {"Create report"}
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            allReports?.data.totalCount > 0 && (
              <>
                <div className="report-table">
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {tableHeader?.map((item, index) => (
                            <TableCell key={index + "item"}>
                              <div className="d-flex gap-1 text-nowrap text-start">{item}</div>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allReports?.data?.reportList?.map((item, ind) => (
                          <TableRow key={item + "report"}>
                            <TableCell>
                              <Tags
                                bgColor={
                                  item.reportType === "ghg"
                                    ? "#B1F0DF"
                                    : item.reportType === "tcfd"
                                    ? "#F9E3CB"
                                    : "#FEE5FA"
                                }
                                border={"#F9E1EF"}
                                tagName={item?.reportType.toUpperCase()}
                              />
                            </TableCell>
                            <TableCell
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "155px"
                              }}
                            >
                              {item.name}
                            </TableCell>
                            <TableCell>{item.fyear || item.year}</TableCell>
                            <TableCell>
                              <p>{item?.createdBy?.name}</p>
                              <Tooltip
                                placement="topLeft"
                                title={item?.createdBy?.email}
                                overlayStyle={{
                                  color: "#FFF",
                                  fontSize: "12px",
                                  fontWeight: "400"
                                }}
                              >
                                <p
                                  className="sub-text"
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "155px"
                                  }}
                                >
                                  {item?.createdBy?.email}
                                </p>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              {dayjs(item?.createdAt).format("DD-MM-YYYY")}
                              <p className="sub-text">{dayjs(item?.createdAt).format("HH:mm:ss")}</p>
                            </TableCell>
                            <TableCell>
                              {item.dueDate ? (
                                <>
                                  {dayjs(item.dueDate).format("DD-MM-YYYY")}
                                  <p className="sub-text">{calculateDaysDifference(item?.dueDate)}</p>
                                </>
                              ) : (
                                <p
                                  className="sub-text"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                  }}
                                >
                                  {"-"}
                                </p>
                              )}
                            </TableCell>
                            {user.reportRole === "Admin" && item && (
                              <TableCell>
                                {item.completionDate ? (
                                  <>
                                    {dayjs(item.completionDate).format("DD-MM-YYYY")}
                                    <p className="sub-text">{timeAgo(item.completionDate)}</p>
                                  </>
                                ) : (
                                  <p
                                    className="sub-text"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center"
                                    }}
                                  >
                                    {"-"}
                                  </p>
                                )}
                              </TableCell>
                            )}

                            <TableCell
                              style={{
                                color:
                                  item.status === "pending"
                                    ? "#FFA11E"
                                    : item.status === "overdue"
                                    ? "#FF535B"
                                    : activeButton === "past"
                                    ? "#16161EAD"
                                    : "#38CB24"
                              }}
                            >
                              {activeButton === "past" ? (
                                <div className="d-flex align-items-center gap-1">
                                  <img src="/images/BRSR/publish-icon-uparrow.svg" alt="icon" />
                                  {capitalizeFirstLetter(item.status)}
                                </div>
                              ) : (
                                capitalizeFirstLetter(item.status)
                              )}
                            </TableCell>
                            <TableCell
                              style={{ color: "#1F93EF", cursor: "pointer" }}
                              onClick={() => moveToDashboard(item)}
                            >
                              {item.status === "completed" || activeButton === "past" ? "" : "Complete >"}
                            </TableCell>
                            {user?.reportRole === "Admin" && (
                              <TableCell onClick={() => openDropDown(item, ind)}>
                                {isLoading.loading && isLoading.id === item._id ? (
                                  <ButtonLoader />
                                ) : (
                                  <Dropdown overlay={menu(item._id)} placement="bottomRight" trigger={["click"]}>
                                    {activeDots === ind ? (
                                      <img
                                        src="/images/BRSR/active-three-dots.svg"
                                        className="cursor-pointer"
                                        alt="icon"
                                      />
                                    ) : (
                                      <img
                                        src="/images/BRSR/de-active-three-dots.svg"
                                        className="cursor-pointer"
                                        alt="icon"
                                      />
                                    )}
                                  </Dropdown>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div className="mt-auto py-4 d-flex justify-content-end">
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(allReports?.data.totalCount / postPerPage)}
                      shape="rounded"
                      onChange={handlePageChange}
                    />
                  </Stack>
                </div>
              </>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default MyReportList;
