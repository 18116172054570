import React from "react";
import "./CustomEmptyState.scss";
import "../../../App.scss";

const EmptyStateCustom = ({ activeMessage, activeSubMessage, image, svg, height, width, children }) => {
  return (
    <div
      className="empty-assigned-state-custom "
      style={{
        height: height,
        ...(width && { width: width })
      }}
    >
      <div style={{ height: "56px", width: "56px" }}>
        {image && <img src={image} alt={"icon"} />}
        {svg && svg}
      </div>
      <div className="d-flex flex-column gap-1 justify-content-center">
        <p className="heading-text text-center">{activeMessage}</p>
        <p className="sub-heading-text text-center">{activeSubMessage}</p>
      </div>
      {children && <div>{children}</div>}
    </div>
  );
};

export default EmptyStateCustom;
