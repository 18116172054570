export const PeerEndPoint = (build) => ({
  getPeerList: build.query({
    query: (arg) => ({
      url: `peer-analysis/get-peers-list`,
      params: { ...arg }
    }),
    providesTags: ["Peerlist"]
  }),
  getPeerAnalysisList: build.query({
    query: (arg) => ({
      url: `peer-analysis/all`,
      params: { ...arg }
    }),
    providesTags: ["Analysislist"]
  }),
  getPeerGraphData: build.query({
    query: (arg) => ({
      url: `peer-analysis/analysis-data`,
      params: { ...arg }
    }),
    providesTags: ["Graphdata"]
  }),
  getBaseCompanyList: build.query({
    query: (arg) => ({
      url: `peer-analysis/get-companies-list`,
      params: { ...arg }
    }),
    providesTags: ["Basecompany"]
  }),
  getIndustryList: build.query({
    query: (arg) => ({
      url: `peer-analysis/get-industry-list`,
      params: { ...arg }
    }),
    providesTags: ["Analysislist"]
  }),
  getRequestData: build.query({
    query: (arg) => ({
      url: `peer-analysis/request-data`,
      params: { ...arg }
    }),
    providesTags: ["Requestdata"]
  }),
  getSelectedPeerData: build.query({
    query: (arg) => ({
      url: `peer-analysis/get-analysis`,
      params: { ...arg }
    }),
    providesTags: ["Selectedpeers"]
  }),
  createPeer: build.mutation({
    query: (body) => ({
      url: "peer-analysis/create",
      method: "POST",
      body: body
    }),
    invalidatesTags: [""]
  }),
  requestPeerData: build.mutation({
    query: (body) => ({
      url: "peer-analysis/request-data",
      method: "POST",
      body: body
    }),
    invalidatesTags: ["Requestdata"]
  }),
  addPeers: build.mutation({
    query: (body) => ({
      url: "peer-analysis/add-peers",
      method: "PUT",
      body: body
    }),
    invalidatesTags: ["Selectedpeers", "Graphdata", "Peerlist"]
  }),
  deletePeer: build.mutation({
    query: (id) => ({
      url: `peer-analysis/delete/${id}`,
      method: "DELETE"
    }),
    invalidatesTags: ["Analysislist"]
  }),
  updatePeer: build.mutation({
    query: (body) => ({
      url: `peer-analysis/update`,
      method: "PUT",
      body: body
    }),
    invalidatesTags: ["Peerlist"]
  })
});
