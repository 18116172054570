import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, Pagination, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { Checkbox, DatePicker, Radio, Select } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import ActionCard from "../../../../../../components/ActionCard/ActionCard";
import { apiUrl } from "../../../../../../components/ApiUrl";
import CustomModal from "../../../../../../components/Modal/Modal";
import { useSnackbar } from "../../../../../../components/snackbar/SnackbarContext";
import { fetchData } from "../../../../../../helper/ErrorHandler";
import {
  useCreateActionActionMutation,
  useCreateActionTemplateMutation,
  useGetCurrencyDataQuery,
  useLazyGetActionMeasureQuery,
  useLazyGetActionPlanQuery,
  useLazyGetActionTemplateFilterQuery,
  useLazyGetActionTemplateQuery,
  useLazyGetAnalysisFilterQuery,
  useGetOrganizationDataQuery,
  usePostAuditLogDataMutation,
  useLazyGetUserDetailByIdQuery
} from "../../../../../../state/api";
import "../../../../scss/commonScss.scss";
import { cleanUpObject } from "../NewActionMeasure";
import "../NewActionMeasure.scss";
import "./ActionCatalogue.scss";
import { useSelector } from "react-redux";

const catArr = [
  "Program and change management",
  "Industrial Processes and Product use (IPPU)",
  "Homeworking",
  "Products",
  "Mobility",
  "Energy",
  "Awareness",
  "Logistic",
  "Waste"
];

const ActionCatalogue = () => {
  const { openSnackbar } = useSnackbar();

  const [getActionTemplate, resultActionTemplate] = useLazyGetActionTemplateQuery();
  const [getActionFilter, resultActionFilter] = useLazyGetActionTemplateFilterQuery();
  const [createTemplate] = useCreateActionTemplateMutation();
  const [getFacFilter, resultFilter] = useLazyGetAnalysisFilterQuery();
  const [getActionPlan, resultActionPlan] = useLazyGetActionPlanQuery();
  const [getActionMeasure, resultActionMeasure] = useLazyGetActionMeasureQuery();
  const [createActionAction] = useCreateActionActionMutation();
  const currData = useGetCurrencyDataQuery();

  const [modalOpen, setModalOpen] = useState(false);
  const postPerPage = 12;
  const [pg, setPage] = useState(0);
  const [modalOpenCreate, setModalOpenCreate] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [actionFilter, setActionFilter] = useState();
  const [resultTemplate, setResultTemplate] = useState("");
  const [actionPostData, setActionPostData] = useState({
    facility: "",
    actionPlan: "",
    actionMeasure: "",
    startDate: "",
    endDate: "",
    targetedValue: "",
    unit: "",
    currentValue: "",
    budget: "",
    currency: ""
  });
  const [filter, setFilter] = useState({
    category: "all",
    impactLevel: "all",
    actionVisibility: "all"
  });
  const [bodyValues, setBodyValues] = useState({
    category: [],
    name: "",
    description: "",
    impactLevel: [],
    actionVisibility: "Private"
  });
  const [logoReader, setLogoReader] = useState(null);
  const [binaryImage, setBinaryImage] = useState(null);
  const [actionTemplateId, setActionTemplateId] = useState(null);

  const [tabSelected, setTabSelected] = useState({
    reductionPotential: { Low: false, Medium: false, High: false },
    budget: { Low: false, Medium: false, High: false },
    complexity: { Low: false, Medium: false, High: false }
  });

  const fileInputRefOrg = useRef(null);

  const userId = useSelector((state) => state.global.user);
  const orgData = useGetOrganizationDataQuery();
  const [postAuditLogData] = usePostAuditLogDataMutation({});
  const [user, setUser] = useState(null);

  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();
  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data);
    }
  }, [userResult.data?.data]);

  useEffect(() => {
    getUser({ id: userId._id }, true);
  }, []);

  useEffect(() => {
    if (actionPostData.facility) {
      if (actionPostData.facility !== "all") {
        getActionPlan({ facility: actionPostData.facility, skip: 0, limit: 200 }, true);
      } else {
        getActionPlan({ skip: 0, limit: 200 }, true);
      }
    }
  }, [actionPostData.facility]);

  useEffect(() => {
    if (resultActionFilter.data?.data) {
      setActionFilter(resultActionFilter.data?.data);
    }
  }, [resultActionFilter.data?.data]);

  useEffect(() => {
    if (resultActionTemplate.data?.data) {
      setResultTemplate(resultActionTemplate.data?.data);
    }
  }, [resultActionTemplate.data?.data]);

  useEffect(() => {
    if (actionPostData.actionPlan) {
      getActionMeasure({ actionPlan: actionPostData.actionPlan, skip: 0, limit: 200 }, true);
    }
  }, [actionPostData.actionPlan]);

  useEffect(() => {
    getFacFilter(
      {
        filters: ["global", "category"]
      },
      true
    );
    if (resultFilter.data) {
      setActionPostData({ ...actionPostData, facility: resultFilter.data.data?.facility[0]?._id });
    }
  }, []);

  useEffect(() => {
    getActionTemplate({ ...filter, skip: pg, limit: postPerPage }, true);
  }, [filter, pg]);

  useEffect(() => {
    getActionFilter({}, true);
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === "") {
      onSearch("");
    }
  };

  const onSearch = (search) => {
    if (search) {
      setFilter({ ...filter, search });
    } else {
      let filterCopy = { ...filter };
      delete filterCopy.search;
      setFilter(filterCopy);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSearch(searchTerm);
    }
  };

  const handleSelectFilterChange = (name, val) => {
    setFilter({ ...filter, [name]: val });
  };

  const handleModalSelectFilterChange = (name, val) => {
    setActionPostData({ ...actionPostData, [name]: val });
  };

  const handleYearChange = (name, dateString) => {
    const formattedDate = dayjs(dateString).format("DD-MM-YYYY");
    setActionPostData({ ...actionPostData, [name]: formattedDate });
  };

  const handlePageChange = (e, value) => {
    if (value - 1 < 0) {
      setPage(0);
    } else {
      setPage((value - 1) * postPerPage);
    }
  };

  const addToAction = async () => {
    let body = { ...actionPostData, actionTemplate: actionTemplateId };
    try {
      const response = await createActionAction(body);
      if (response.error) {
        openSnackbar(response.error.data?.message, "error");
      } else {
        openSnackbar(response.data?.message, "success");
        setActionPostData({
          facility: "",
          actionPlan: "",
          actionMeasure: "",
          startDate: "",
          endDate: "",
          targetedValue: "",
          unit: "",
          currentValue: "",
          budget: "",
          currency: ""
        });
        setModalOpen(false);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      openSnackbar(errorMessage, "error");
    }
  };

  const handleButtonClickOrg = () => {
    fileInputRefOrg.current.click();
  };

  const handleImageChange = (event) => {
    try {
      const file = event.target.files;

      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setBinaryImage(file[0]);
          setLogoReader(reader.result);
        };
        reader.readAsDataURL(file[0]);
      }
    } catch (error) {
      openSnackbar(error.message, "error");
    }
  };

  const handleImageUploadTemp = async (templateId) => {
    if (binaryImage) {
      const formData = new FormData();
      formData.append("image", binaryImage);
      formData.append("id", templateId);

      try {
        await fetchData(`${apiUrl}/act/template/update/image`, {
          method: "PUT",
          credentials: "include",
          body: formData
        });
      } catch (error) {
        throw error;
      }
    }
  };

  const onCheckChange = (e) => {
    let impLvl = [...bodyValues.impactLevel];
    if (impLvl.indexOf(e.target.name) === -1) {
      impLvl.push(e.target.name);
    } else {
      impLvl = impLvl.filter((val) => val !== e.target.name);
    }
    setBodyValues({ ...bodyValues, impactLevel: impLvl });
  };

  const onRadioChange = (e) => {
    setBodyValues({ ...bodyValues, actionVisibility: e.target.value });
  };

  const handleModalFieldChange = (e) => {
    setBodyValues({ ...bodyValues, [e.target.name]: e.target.value });
  };

  const addRemoveCategory = (item) => {
    let cats = [...bodyValues.category];
    if (cats.indexOf(item) === -1) {
      cats.push(item);
    } else {
      cats = cats.filter((val) => val !== item);
    }
    setBodyValues({ ...bodyValues, category: cats });
  };

  const createActions = async () => {
    try {
      let tabSelectedBody = cleanUpObject(tabSelected);
      let bodyObj = { ...bodyValues, ...tabSelectedBody };
      let response = await createTemplate(bodyObj);
      if (response.data && binaryImage) {
        handleImageUploadTemp(response.data.data?._id);
      }
      if (response.error) {
        openSnackbar(response.error.data?.message, "error");
      } else {
        openSnackbar(response.data?.message, "success");
        postAuditLogData({
          action: "CREATE",
          message: `${bodyValues.name} created by  ${user.name}`,
          tab: "Action Plan",
          facility: "",
          organization: orgData.data?.data._id,
          user: userId._id,
          ipAddress: "10.10.0.1"
        });

        setModalOpenCreate(false);
        setBodyValues({
          category: [],
          name: "",
          description: "",
          impactLevel: [],
          actionVisibility: "Private"
        });
        setBinaryImage(null);
        setTabSelected({
          reductionPotential: { Low: false, Medium: false, High: false },
          budget: { Low: false, Medium: false, High: false },
          complexity: { Low: false, Medium: false, High: false }
        });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      openSnackbar(errorMessage, "success");
    }
  };

  const handleButtonClick = (category, name) => {
    switch (category) {
      case "Low":
        setTabSelected({ ...tabSelected, [name]: { Low: true, Medium: false, High: false } });
        break;
      case "Medium":
        setTabSelected({ ...tabSelected, [name]: { Low: false, Medium: true, High: false } });
        break;
      case "High":
        setTabSelected({ ...tabSelected, [name]: { Low: false, Medium: false, High: true } });
        break;
      default:
        break;
    }
  };

  const handleActionInputChange = (e) => {
    setActionPostData({ ...actionPostData, [e.target.name]: e.target.value });
  };

  const openActionModal = (id) => {
    setModalOpen(true);
    setActionTemplateId(id);
  };

  return (
    <div>
      <Tooltip id={"modal-info-action"} style={{ width: "300px", zIndex: 9991 }} place="right" />

      <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen} outSideClose={false}>
        <div className="main-modal-container modal-container-wide">
          <div className="d-flex gap-2 align-items-center">
            <p style={{ fontSize: "24px", fontWeight: "bold" }}>Add action to action plan</p>
            <div
              data-tooltip-id={"modal-info-action"}
              data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
            >
              <InfoOutlinedIcon />
            </div>
          </div>
          <div className="divider-container">
            <h6 className="line-divider ">
              <span className="span-line-divider mt-2">Select Action Plan</span>
            </h6>
          </div>
          <div className="w-50">
            <p className="mb-2 fs-12p">
              Facility <sup>*</sup>
            </p>
            <Select
              value={actionPostData.facility}
              placeholder="Facility"
              optionFilterProp="facility"
              size="large"
              onChange={(val) => handleModalSelectFilterChange("facility", val)}
              className="w-100"
            >
              {/* <Select.Option value={"all"}>All Facilties</Select.Option> */}
              {resultFilter.data?.data?.facility?.map((item, key) => (
                <Select.Option key={key} value={item._id} label={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="d-flex gap-2 mt-3">
            <div className="w-50">
              <p className="mb-2 fs-12p">Action plan</p>
              <Select
                value={actionPostData.actionPlan}
                placeholder="Action Plan"
                optionFilterProp="actionPlan"
                size="large"
                onChange={(val) => handleModalSelectFilterChange("actionPlan", val)}
                className="w-100"
              >
                {/* <Select.Option value={"all"}>All Actions</Select.Option> */}
                {resultActionPlan.data?.data?.actionPlanList?.map((item, key) => (
                  <Select.Option key={key} value={item._id} label={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="w-50">
              <p className="mb-2 fs-12p">Reduction Measure</p>
              <Select
                value={actionPostData.actionMeasure}
                placeholder="Status"
                optionFilterProp="facility"
                size="large"
                onChange={(val) => handleModalSelectFilterChange("actionMeasure", val)}
                className="w-100"
              >
                {/* <Select.Option value={"all"}>All Measures</Select.Option> */}
                {resultActionMeasure.data?.data?.actionMeasureList?.map((item, key) => (
                  <Select.Option key={key} value={item._id} label={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>

          <div className="divider-container">
            <h6 className="line-divider ">
              <span className="span-line-divider mt-2">Period</span>
            </h6>
          </div>
          <div className="d-flex gap-2 mt-3">
            <div className="w-50">
              <p className="mb-2 fs-12p">Start Date</p>
              <DatePicker
                value={actionPostData.startDate && dayjs(actionPostData.startDate, "DD-MM-YYYY")}
                format={"DD-MM-YYYY"}
                onChange={(val) => handleYearChange("startDate", val)}
                size="large"
                placeholder="dd-mm-yyyy"
                className="w-100"
              />
            </div>
            <div className="w-50">
              <p className="mb-2 fs-12p">End Date</p>
              <DatePicker
                value={actionPostData.endDate && dayjs(actionPostData.endDate, "DD-MM-YYYY")}
                format={"DD-MM-YYYY"}
                onChange={(val) => handleYearChange("endDate", val)}
                size="large"
                placeholder="dd-mm-yyyy"
                className="w-100"
              />
            </div>
          </div>
          <div className="divider-container">
            <h6 className="line-divider ">
              <span className="span-line-divider mt-2 fs-12p">Target</span>
            </h6>
          </div>
          <div className="d-flex gap-2">
            <div className="flex-fill">
              <p className="mb-2 fs-12p">
                Targeted Value <sup>*</sup>
              </p>
              <input
                type="text"
                className="form-control"
                placeholder="Target"
                aria-label="Target"
                name="targetedValue"
                value={actionPostData.targetedValue}
                onChange={handleActionInputChange}
              />
            </div>
            <div className="flex-fill">
              <div className="d-flex gap-2">
                <p className="mb-2 fs-12p">
                  Unit <sup>*</sup>
                </p>
                <div
                  data-tooltip-id={"modal-info-unit"}
                  data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
                >
                  <InfoOutlinedIcon />
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Unit"
                aria-label="Unit"
                name="unit"
                value={actionPostData.unit}
                onChange={handleActionInputChange}
              />
            </div>
            <div className="flex-fill">
              <div className="d-flex gap-2">
                <p className="mb-2 fs-12p">
                  Current Value <sup>*</sup>
                </p>
                <div
                  data-tooltip-id={"modal-info-current"}
                  data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
                >
                  <InfoOutlinedIcon />
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Current"
                aria-label="Current"
                name="currentValue"
                value={actionPostData.currentValue}
                onChange={handleActionInputChange}
              />
            </div>
          </div>
          <div className="divider-container">
            <h6 className="line-divider ">
              <span className="span-line-divider mt-2">Budget</span>
            </h6>
          </div>
          <div className="d-flex gap-2 mt-3">
            <div className="w-50">
              <p className="mb-2 fs-12p">
                Budget <sup>*</sup>
              </p>
              <input
                type="text"
                className="form-control"
                placeholder="Budget"
                aria-label="Budget"
                name="budget"
                value={actionPostData.budget}
                onChange={handleActionInputChange}
              />
            </div>
            <div className="w-50">
              <p className="mb-2 fs-12p">Currency</p>
              <Select
                value={actionPostData.currency}
                placeholder="Currency"
                optionFilterProp="currency"
                size="large"
                onChange={(val) => handleModalSelectFilterChange("currency", val)}
                className="w-100"
              >
                {currData.data?.data.map((item, key) => (
                  <Select.Option key={key} value={item._id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="button-container">
            <button type="button" className="btn btn-outline-success" onClick={() => setModalOpen(false)}>
              Cancel
            </button>
            <button type="button" className="btn btn-success" onClick={addToAction}>
              Add to action plan
            </button>
          </div>
        </div>
      </CustomModal>
      <CustomModal modalOpen={modalOpenCreate} setModalOpen={setModalOpenCreate} outSideClose={false}>
        <div className="main-modal-container">
          <div className="modal-container-wide">
            <p style={{ fontSize: "24px", fontWeight: "bold" }}>Create new action</p>
          </div>

          <div className="main-image-container mt-4">
            <div>
              <div className="image-container-div-fac">
                <div className="upload-image-div">
                  <img
                    src={logoReader ? logoReader : "/images/add-org-logo.png"}
                    alt="org-logo"
                    width="100%"
                    height="100%"
                    objectfit="cover"
                  />
                </div>
                {logoReader ? (
                  <div className="add-image-icon" onClick={handleButtonClickOrg}>
                    +
                  </div>
                ) : (
                  <div className="add-image-icon" onClick={handleButtonClickOrg}>
                    +
                  </div>
                )}
              </div>
              <input
                type="file"
                accept="image/*"
                ref={fileInputRefOrg}
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </div>
            <div className="flex-fill fs-12p">
              <div>
                <p className="labelTextModal mb-2">Action Name</p>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Action Name"
                    aria-label="Action Name"
                    name="name"
                    value={bodyValues.name}
                    onChange={handleModalFieldChange}
                  />
                </div>
              </div>
              <div>
                <p className="labelTextModal my-2">Description</p>
                <div className="custom-textfield">
                  <textarea
                    id="foreword"
                    rows="3"
                    placeholder="Description"
                    name="description"
                    value={bodyValues.description}
                    onChange={handleModalFieldChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="divider-container">
            <h6 className="line-divider ">
              <span className="span-line-divider mt-2">Category</span>
            </h6>
          </div>
          <div className="d-flex flex-wrap gap-2">
            {catArr.map((item) => (
              <div
                className={`category-tags cursor-pointer ${bodyValues.category.indexOf(item) !== -1 ? "bg-sb" : ""}`}
                onClick={() => addRemoveCategory(item)}
              >
                <p>{item}</p>
              </div>
            ))}
          </div>
          <div className="d-flex mt-4 gap-5">
            <div className="border-impact">
              <div className="mb-3">
                <div className="d-flex gap-2 align-items-center">
                  <p className="fw-bold">Reduction Potential</p>
                  <div
                    data-tooltip-id={"modal-info-reductionPotential"}
                    data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
                  >
                    <InfoOutlinedIcon />
                  </div>
                </div>
                <div className="selection-tabs-modal mt-2">
                  <div
                    onClick={() => handleButtonClick("Low", "reductionPotential")}
                    className={`tab-select ${tabSelected.reductionPotential.Low ? "active-tab-red" : ""}`}
                  >
                    <p>Low</p>
                  </div>
                  <div
                    onClick={() => handleButtonClick("Medium", "reductionPotential")}
                    className={`tab-select ${tabSelected.reductionPotential.Medium ? "active-tab-yellow" : ""}`}
                  >
                    <p>Medium</p>
                  </div>
                  <div
                    onClick={() => handleButtonClick("High", "reductionPotential")}
                    className={`tab-select ${tabSelected.reductionPotential.High ? "active-tab-green" : ""}`}
                  >
                    <p>High</p>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex gap-2 align-items-center">
                  <p className="fw-bold">Budget</p>
                  <div
                    data-tooltip-id={"modal-info-budget"}
                    data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
                  >
                    <InfoOutlinedIcon />
                  </div>
                </div>
                <div className="selection-tabs-modal mt-2">
                  <div
                    onClick={() => handleButtonClick("Low", "budget")}
                    className={`tab-select ${tabSelected.budget.Low ? "active-tab-red" : ""}`}
                  >
                    <p>Low</p>
                  </div>
                  <div
                    onClick={() => handleButtonClick("Medium", "budget")}
                    className={`tab-select ${tabSelected.budget.Medium ? "active-tab-yellow" : ""}`}
                  >
                    <p>Medium</p>
                  </div>
                  <div
                    onClick={() => handleButtonClick("High", "budget")}
                    className={`tab-select ${tabSelected.budget.High ? "active-tab-green" : ""}`}
                  >
                    <p>High</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex gap-2 align-items-center">
                  <p className="fw-bold">Complexity</p>
                  <div
                    data-tooltip-id={"modal-info-complexity"}
                    data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
                  >
                    <InfoOutlinedIcon />
                  </div>
                </div>
                <div className="selection-tabs-modal mt-2">
                  <div
                    onClick={() => handleButtonClick("Low", "complexity")}
                    className={`tab-select ${tabSelected.complexity.Low ? "active-tab-red" : ""}`}
                  >
                    <p>Low</p>
                  </div>
                  <div
                    onClick={() => handleButtonClick("Medium", "complexity")}
                    className={`tab-select ${tabSelected.complexity.Medium ? "active-tab-yellow" : ""}`}
                  >
                    <p>Medium</p>
                  </div>
                  <div
                    onClick={() => handleButtonClick("High", "complexity")}
                    className={`tab-select ${tabSelected.complexity.High ? "active-tab-green" : ""}`}
                  >
                    <p>High</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="d-flex gap-2 align-items-center">
                  <p className="fw-bold">Impact Level</p>
                  <div
                    data-tooltip-id={"modal-info-complexity"}
                    data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
                  >
                    <InfoOutlinedIcon />
                  </div>
                </div>
                <div className="d-flex flex-column mt-2">
                  <Checkbox name="Employee" onChange={onCheckChange}>
                    Employee
                  </Checkbox>
                  <Checkbox name="Company" onChange={onCheckChange}>
                    Company
                  </Checkbox>
                </div>
              </div>
              <div className="mt-3">
                <div className="d-flex gap-2 align-items-center">
                  <p className="fw-bold">Action Visibility</p>
                  <div
                    data-tooltip-id={"modal-info-complexity"}
                    data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
                  >
                    <InfoOutlinedIcon />
                  </div>
                </div>
                <div className="mt-2">
                  <Radio.Group onChange={onRadioChange} value={bodyValues.actionVisibility}>
                    <Radio value={"Public"}>Public</Radio>
                    <Radio value={"Private"}>Private</Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>
          </div>

          <div className="button-container">
            <button type="button" className="btn btn-success" onClick={createActions}>
              Create Action
            </button>
            <button type="button" className="btn btn-outline-success" onClick={() => setModalOpenCreate(false)}>
              Cancel
            </button>
          </div>
        </div>
      </CustomModal>
      <div className="d-flex gap-2 align-items-center">
        <p className="box-title-name">Actions Catalogue</p>
        <div
          data-tooltip-id={"modal-info-action"}
          data-tooltip-html={`<span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 600; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">Completion year for the simulation<br/></span><span style="color: white; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word"><br/></span><span style="color: #C6CBD9; font-size: 12px; font-family: Poppins; font-weight: 400; line-height: 16px; letter-spacing: 0.05px; word-wrap: break-word">End year may or may not be the same as any of your target years.</span>`}
        >
          <InfoOutlinedIcon />
        </div>
      </div>
      <div className="d-flex flex-wrap justify-content-between gap-2 flex-fill mt-3">
        <TextField
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyPress={handleKeyPress}
          sx={{ background: "white", border: "none" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{ cursor: "pointer" }}>
                <SearchIcon />
              </InputAdornment>
            )
          }}
          InputLabelProps={{
            style: { top: "-6" }
          }}
          className="ps-search-bar-wide ps-search-bar"
        />
        <Select
          value={filter.category}
          placeholder="Category"
          optionFilterProp="category"
          size="large"
          onChange={(val) => handleSelectFilterChange("category", val)}
          className="mw-175p"
        >
          <Select.Option value={"all"}>All</Select.Option>
          {actionFilter?.category?.map((item, key) => (
            <Select.Option key={key} value={item} label={item}>
              {item}
            </Select.Option>
          ))}
        </Select>

        <Select
          value={filter.impactLevel}
          placeholder="Impact Level"
          size="large"
          onChange={(val) => handleSelectFilterChange("impactLevel", val)}
          className="mw-175p"
        >
          <Select.Option value={"all"}>All Types</Select.Option>
          {actionFilter?.impactLevel?.map((item, key) => (
            <Select.Option key={key} value={item} label={item}>
              {item}
            </Select.Option>
          ))}
        </Select>

        <Select
          value={filter.actionVisibility}
          placeholder="Visibility"
          size="large"
          onChange={(val) => handleSelectFilterChange("actionVisibility", val)}
          className="mw-175p"
        >
          <Select.Option value={"all"}>All Visibility</Select.Option>
          {actionFilter?.actionVisibility?.map((item, key) => (
            <Select.Option key={key} value={item} label={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
        <button className="btn btn-success create-new-button-wide" onClick={() => setModalOpenCreate(true)}>
          + Create new action
        </button>
      </div>
      <div className="mt-4 action-card-container">
        {resultTemplate?.actionTemplateList?.map((item) => (
          <ActionCard
            title={item.name}
            description={item.description}
            tags={item.category}
            logo={item.logo}
            item={item}
            openActionModal={openActionModal}
            setModalOpen={setModalOpen}
          />
        ))}
      </div>
      <div className="mt-auto">
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(resultTemplate?.totalCount / postPerPage)}
            shape="rounded"
            onChange={handlePageChange}
          />
        </Stack>
      </div>
    </div>
  );
};

export default ActionCatalogue;
