import { createSlice } from "@reduxjs/toolkit";

const initialVariableState = {
  reportId: null,
  reportCreationData: null,
  reportType: null
};

export const reportVariableSlice = createSlice({
  name: "social",
  initialState: initialVariableState,
  reducers: {
    setVariableData: (state, action) => {
      return {
        ...state,
        reportId: action.payload.reportId
      };
    },
    setReportType: (state, action) => {
      return {
        ...state,
        reportType: action.payload.reportType
      };
    },
    setResponseCreationData: (state, action) => {
      return {
        ...state,
        reportCreationData: {
          creationSource: action.payload.creationSource,
          baseReportYear: action.payload.baseReportYear
        }
      };
    }
  }
});

export const { setVariableData, setResponseCreationData, setReportType } = reportVariableSlice.actions;

export default reportVariableSlice.reducer;
