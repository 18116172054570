import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomModal from "../../components/Modal/Modal";
import CustomTabs from "../../components/Tabs/CustomTabs";
import { useSnackbar } from "../../components/snackbar/SnackbarContext";
import {
  useLazyGetUserDetailByIdQuery,
  usePostEmissionTableDataMutation,
  usePostAuditLogDataMutation,
  useGetOrganizationDataQuery
} from "../../state/api";
import { setTabWiseValueGlobal, setTabs } from "../../state/multiplCategorySlice";
import DataTable from "./DataTable/DataTable";
import SelectCards from "./SelectCards/SelectCards";
import "./SingleUpload.scss";
// import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

// const calenderIcon = <CalendarTodayIcon />

function convertStringNumbers(data) {
  for (let key in data) {
    if (Array.isArray(data[key])) {
      data[key] = data[key].map((item) => {
        for (let itemKey in item) {
          if (typeof item[itemKey] === "string" && !isNaN(item[itemKey]) && item[itemKey].trim() !== "") {
            item[itemKey] = parseFloat(item[itemKey]);
          }
        }
        return item;
      });
    }
  }
  return data;
}

const SingleUpload = () => {
  const { openSnackbar } = useSnackbar();
  const [postEmissionTableData] = usePostEmissionTableDataMutation({});
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  // const disabledDate = (current) => {
  //   return current && current > dayjs().endOf("day");
  // };

  const receivedDataId = location.state?.data;
  const receivedDataTitle = location.state?.title;
  const receivedDataImage = location.state?.image;

  const { tabWiseValueGlobal, tabs } = useSelector((state) => state.category);
  const orgData = useGetOrganizationDataQuery();
  const [postAuditLogData] = usePostAuditLogDataMutation({});

  const [activeTab, setActiveTab] = useState(receivedDataId);
  const [openSelectCategoryModal, setOpenSelectCategoryModal] = useState(false);
  const [facData, setFacData] = useState({
    facility: "",
    year: dayjs()
  });
  const [canEdit, setCanEdit] = useState({
    facEdit: false,
    yearEdit: false
  });
  // const [facDropDown, setFacDropDown] = useState(false);
  // const [dateDropDown, setDateDropDown] = useState(false);

  const [apiLoading, setApiLoading] = useState(false);

  const userId = useSelector((state) => state.global.user);
  const [user, setUser] = useState(null);
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();

  useEffect(() => {
    getUser({ id: userId._id }, true);
  }, []);

  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data);
    }
  }, [userResult.data?.data]);

  useEffect(() => {
    if (user?.facilities && user?.facilities?.length > 0) setFacData({ ...facData, facility: user.facilities[0]._id });
  }, [user?.facilities]);

  useEffect(() => {
    let existingTabs = JSON.parse(JSON.stringify(tabs));

    const tabExists = existingTabs.some((obj) => obj.id === receivedDataId);

    if (!tabExists) {
      dispatch(
        setTabs([
          ...existingTabs,
          {
            id: receivedDataId,
            title: receivedDataTitle,
            image: receivedDataImage
          }
        ])
      );
    }
  }, [receivedDataId, receivedDataTitle, receivedDataImage, dispatch]);

  const handleChange = (e, valueObj) => {
    setCanEdit({ ...canEdit, facEdit: false });
    setFacData({ ...facData, facility: valueObj.value });
  };

  const handleYearChange = (value, dateString) => {
    if (dateString) {
      setCanEdit({ ...canEdit, yearEdit: false });
      setFacData({ ...facData, year: dayjs(dateString) });
    } else {
      setFacData({ ...facData, year: undefined });
    }
  };

  const handleTabChange = (cardId) => {
    setActiveTab(cardId);
  };

  const handleSubmit = async (e) => {
    if (apiLoading) return;
    try {
      setApiLoading(true);
      if (!facData.year) {
        openSnackbar("Year and month are required", "warning");
        setApiLoading(false);
        return;
      }

      if (!facData.facility) {
        openSnackbar("Facility is required", "warning");
        setApiLoading(false);
        return;
      }

      const date = dayjs(facData.year, "YYYY-MM");
      const year = date.format("YYYY");
      const monthAbbreviation = date.format("MMM");
      let finalData = {};

      for (let tab of tabs) {
        const emissionData = [];
        const tabData = tabWiseValueGlobal[tab.id];
        for (const [index1, mandatoryCheck] of Object.entries(tabData.isMandatoryFilled)) {
          if (!mandatoryCheck) {
            openSnackbar(
              `Row ${parseFloat(index1) + 1} of ${tab.title} does not have mandatory fields filled.`,
              "warning"
            );
            setApiLoading(false);
            return;
          }
        } //Mandatory fields check

        for (let [rowIndex, row] of tabData.rowDataArr.entries()) {
          if (row.totalEmission !== 0 && !row.totalEmission) {
            openSnackbar(
              `Row ${parseFloat(rowIndex) + 1} of ${tab.title} does not have emission calculated.`,
              "warning"
            );
            setApiLoading(false);
            return;
          }

          row = Object.fromEntries(Object.entries(row).filter(([key, value]) => value || value === 0));
          row = { ...row, year, month: monthAbbreviation, facility: facData.facility, bill: row?.bill?._id };
          emissionData.push({ ...row });
        } //Row Data and emission factor check

        finalData[tab.id] = emissionData;
      }

      convertStringNumbers(finalData);

      let res = await postEmissionTableData(finalData);

      if (res?.data?.success) {
        openSnackbar(res.data?.message, "success");

        let facilityName = (user?.facilities.find((item) => item._id === facData.facility) || {}).name;
        postAuditLogData({
          action: "CREATE",
          message: `${user.name} added new data for ${receivedDataTitle} for ${facData.year} and ${facilityName}`,
          tab: "Measure",
          facility: "",
          organization: orgData.data?.data._id,
          user: userId._id,
          ipAddress: "10.10.0.1"
        });
        cancelDataUpload();
      } else {
        openSnackbar(res?.error?.data?.message, "warning");
        setApiLoading(false);
      }
    } catch (err) {
      openSnackbar(err.message, "error");
      setApiLoading(false);
    }
  };

  // const enableEdit = () => {
  //   let facDataObj = {
  //     facility: "",
  //     year: ""
  //   };
  //   setFacData(facDataObj);
  //   setCanEdit({ facEdit: true, yearEdit: true });

  //   setFacDropDown(true);
  //   setDateDropDown(false);
  // };

  const closeTab = (value) => {
    let allTab = JSON.parse(JSON.stringify(tabs));
    let allTabVal = JSON.parse(JSON.stringify(tabWiseValueGlobal));
    const { [value]: _, ...newObj } = allTabVal;
    let remainingTabs = allTab.filter((item) => item.id !== value);

    if (value === activeTab) {
      setActiveTab(remainingTabs[0]?.id);
    }

    dispatch(setTabs(remainingTabs));
    dispatch(setTabWiseValueGlobal(newObj));

    if (remainingTabs.length === 0) {
      navigate("/measure");
    }
  };

  const cancelSelect = () => {
    setOpenSelectCategoryModal(false);
  };

  const addSelectedCards = (arrOfCards) => {
    let tabVal = JSON.parse(JSON.stringify(tabs));
    let newTabs = [...tabVal, ...arrOfCards];
    dispatch(setTabs(newTabs));
    setOpenSelectCategoryModal(false);
  };

  const cancelDataUpload = () => {
    dispatch(setTabWiseValueGlobal({}));
    dispatch(setTabs([]));
    navigate(-1);
  };

  useEffect(() => {
    if (tabs.length > 1 && receivedDataTitle !== "Air Emission") {
      let allTabVal = JSON.parse(JSON.stringify(tabWiseValueGlobal));
      let airTab = tabs.find((item) => item.title === "Air Emission");
      if (airTab) {
        const filteredData = tabs.filter((item) => item.title !== "Air Emission");
        dispatch(setTabs(filteredData));
        delete allTabVal[airTab.id];
        dispatch(setTabWiseValueGlobal(allTabVal));
      }
    } else if (tabs.length > 1 && receivedDataTitle === "Air Emission") {
      let allTabVal = JSON.parse(JSON.stringify(tabWiseValueGlobal));
      let airTab = tabs.find((item) => item.title === "Air Emission");
      if (airTab && tabWiseValueGlobal.hasOwnProperty(airTab.id)) {
        const filteredData = tabs.filter((item) => item.title === "Air Emission");
        dispatch(setTabs(filteredData));
        let airTabVal = { [airTab.id]: allTabVal[airTab.id] };
        dispatch(setTabWiseValueGlobal(airTabVal));
      }
    }
  }, [tabs.length, tabWiseValueGlobal]);

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("month");
  };

  return (
    <>
      {
        <CustomModal modalOpen={openSelectCategoryModal} setModalOpen={setOpenSelectCategoryModal} outSideClose={false}>
          <div className="selectCardsContainer">
            <SelectCards addSelectedCards={addSelectedCards} cancelSelect={cancelSelect} activeTabId={tabs} />
          </div>
        </CustomModal>
      }
      <div className="d-flex flex-column h-100">
        <div>
          <h3>Data Upload Session</h3>
        </div>
        <div className="filterContent">
          <div className="d-flex">
            <Select
              showSearch
              // open={facDropDown}
              // onFocus={() => setFacDropDown(true)}
              // onBlur={() => setFacDropDown(false)}
              value={facData.facility !== "" ? facData.facility : null}
              placeholder="Select a facility"
              name="facility"
              optionFilterProp="facility"
              size="large"
              onChange={handleChange}
              style={{ width: "200px" }}
              className="custom-select-antd"  
            >
              {user?.facilities?.map((item, ind) => (
                <Select.Option key={ind} value={item._id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
            <div className="calenderSelect">
              <DatePicker
                picker="month"
                defaultValue={dayjs()}
                value={facData.year ? facData.year : undefined}
                format={"YYYY-MM"}
                // open={dateDropDown}
                // onFocus={() => setDateDropDown(true)}
                // onBlur={() => setDateDropDown(false)}
                onChange={handleYearChange}
                size="large"
                placeholder="Select month and year"
                style={{ width: "230px" }}
                disabledDate={disabledDate}
                // suffixIcon={calenderIcon}
                // disabledDate={disabledDate}
              />
            </div>
            {/* <img src="/images/icons/edit.svg" alt="" className="cursor-pointer ms-2" onClick={() => enableEdit()} /> */}
          </div>
          <div className="buttonContainer">
            <button type="button" className="btn btn-success" onClick={handleSubmit}>
              Submit
            </button>
            <button type="button" className="btn btn-outline-success ms-2" onClick={cancelDataUpload}>
              Cancel
            </button>
          </div>
        </div>
        <div className="tab-wrapper">
          {tabs?.map((item, key) => (
            <CustomTabs
              key={key + "tabs"}
              closeTab={() => closeTab(item.id)}
              value={item.id}
              image={`/images/templateLogo/${item.image}`}
              title={item.title}
              activeTab={activeTab}
              handleTabChange={handleTabChange}
            />
          ))}
          {tabs?.find((item) => item.id === activeTab)?.title !== "Air Emission" && (
            <div className="tab-container inactive" onClick={() => setOpenSelectCategoryModal(true)}>
              <img src={"/images/icons/add-icon.svg"} alt="add" />
              <div className="title">Add new category</div>
            </div>
          )}
        </div>
        <div className="table-wrapper">
          <DataTable
            key={activeTab}
            facData={facData}
            receivedDataId={activeTab}
            name={tabs?.find((item) => item.id === activeTab)?.title}
          />
        </div>
      </div>
    </>
  );
};

export default SingleUpload;
