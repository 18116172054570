import React, { useEffect, useState } from "react";
import "./GlobalAssignQuestionsBulk.scss";
import { Button, Checkbox, Select } from "antd";
import "../common.scss";
import "../../../App.scss";
import SearchBar from "../../../components/BRSRComponets/SearchBar/SearchBar";
import Chip from "../../../components/BRSRComponets/Chip/Chip";
import BRSRAccordion from "../../../components/BRSRComponets/BRSRAccordion/BRSRAccordion";
import {
  useLazyGetAllReportsQuery,
  useLazyGetAssignmentQuestionsQuery,
  useLazyGetAssignmentStatusQuery,
  useLazyGetContributorsQuery,
  useLazyGetUserDepartmentQuery,
  useLazyGetUserDetailByIdQuery,
  usePostAssignQuestionMutation,
  usePostChangeDepartmentMutation
} from "../../../state/api";
import { useSnackbar } from "../../../components/snackbar/SnackbarContext";
import { useSelector } from "react-redux";
import CustomModal from "../../../components/Modal/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import BRSRButton from "../../../components/BRSRComponets/BRSRButton/BRSRButton";
import WidgetLoader from "../../../components/Loaders/WidgetLoader";
import EmptyState from "../../../components/BRSRComponets/EmptyState/emptyState";
import { facilityAssignmentMap, indicatorMap, principlesMap, sectionMap, sections } from "../../../helper/constant";
import CustomSectionDropdown from "../../../components/BRSRComponets/CustomSectionDropdown/CustomSectionDropdown";
import { Virtuoso } from "react-virtuoso";
import { GlobalBulkAssignDrawer } from "./GlobalBulkAssignDrawer/GlobalBulkAssignDrawer";

const GlobalAssignQuestionsBulk = () => {
  const [getQuestions, resultQuestions] = useLazyGetAssignmentQuestionsQuery();
  const [getContributors, resultContributors] = useLazyGetContributorsQuery();
  const [getAssignmentStatus, resultAssignmentStatus] = useLazyGetAssignmentStatusQuery();
  const [getUserDepartment, resultDepartment] = useLazyGetUserDepartmentQuery();
  const [postChangeDepartment] = usePostChangeDepartmentMutation();
  const [getAllReports, resultAllReports] = useLazyGetAllReportsQuery();

  console.log("resultDepartment====", resultDepartment)

  const facilityAssignmentArr = ["Single Facilities", "Multiple Facilities"];
  const questionStatusArr = ["Assigned", "Not Assigned"];

  const colors = ["#38CB24", "#F44336", "#09AB8E", "#007A64", "#9C27B0", "#BA68C8", "#64B5F6"];

  const navigate = useNavigate();

  const location = useLocation();
  const reportId = location.state.reportId;
  const reportFyYear = location.state.reportingYear;

  const [showModal, setShowModal] = useState(false);
  const userId = useSelector((state) => state.global.user);
  const [user, setUser] = useState(null);
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();
  const [departmentVal, setDepartmentVal] = useState();
  const [dotFilter, setDotFilter] = useState({
    isAnswered: null,
    isUnanswered: null,
    isApproved: null,
    isRejected: null,
    isPendingApproval: null,
    isAccepted: null,
    notAccepted: null,
    overdue: null,
    isAssigned: null,
    notAssigned: null
  });

  const [assignQuestion] = usePostAssignQuestionMutation();
  const { openSnackbar } = useSnackbar();

  const [page, setPage] = useState(0);
  const pageSize = 8;

  const [error, setError] = useState([]);
  const [question, setQuestion] = useState(null);
  const [department, setDepartment] = useState("");
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [questionType, setQuestionType] = useState("");
  const [selectedQuestionType, setSelectedQuestionType] = useState(null);
  const [selectedQuestionStatus, setSelectedQuestionStatus] = useState(null);
  const [facFilter, setFacFilter] = useState("ALL");
  const [bulkAssignDrawer, setBulkAssignDrawer] = useState(false);

  const [questionSelected, setQuestionSelected] = useState({});
  const [questionSelectedDetail, setQuestionSelectedDetail] = useState([]);
  const [questionId, setQuestionId] = useState("");
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSelected, setIsSelected] = useState("");
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const [subSection, setSubSection] = useState("");
  const [indicator, setIndicator] = useState("");
  const [reportData, setReportData] = useState(null);

  const getBackendSectonKey = (section) => {
    return Object.keys(sectionMap).find((key) => sectionMap[key] === section?.trim());
  };

  const getBackendSubSectonKey = (subSection) => {
    return Object.keys(principlesMap).find((key) => principlesMap[key] === subSection?.trim());
  };

  const getFacilityAssignmentKey = (questionType) => {
    return Object.keys(facilityAssignmentMap).find((key) => facilityAssignmentMap[key] === questionType);
  };

  const getIndicatorBackendKey = (indicator) => {
    return Object.keys(indicatorMap).find((key) => indicatorMap[key] === indicator?.trim());
  };

  const handleChange = (value) => {
    const [dropDownSection, dropDownSubSection, dropdownIndicator] = value.split(" / ");
    const sectionBackendKey = getBackendSectonKey(dropDownSection);

    setIsSelected(sectionBackendKey);

    if (dropDownSubSection) {
      if (isSelected === "SectionC" || sectionBackendKey === "SectionC") {
        const subSectionBackendKey = getBackendSubSectonKey(dropDownSubSection);
        setSubSection(subSectionBackendKey);

        if (dropdownIndicator) {
          const indicatorBackendKey = getIndicatorBackendKey(dropdownIndicator);
          setIndicator(indicatorBackendKey);
        } else {
          setIndicator("");
        }
      } else {
        setSubSection(dropDownSubSection);
      }
    } else {
      setSubSection("");
      setIndicator("");
    }
  };

  const handleDepartmentChange = (value) => {
    if (value.includes("ALL")) {
      const allDepartments = resultDepartment?.data?.data?.map((item) => item.name) || [];
      setSelectedDepartments(["ALL"]);
      setDepartment(allDepartments.join(","));
    } else {
      setSelectedDepartments(value);
      setDepartment(value.join(","));
    }
  };

  const handleFacilityAssignmentChange = (value) => {
    if (value !== "BOTH") {
      setSelectedQuestionType(value);
      const facilityAssignmentKey = getFacilityAssignmentKey(value);
      setQuestionType(facilityAssignmentKey);
    } else {
      setSelectedQuestionType(value);
      setQuestionType("BOTH");
    }
  };

  const handleQuestionStatusChange = (value) => {
    setSelectedQuestionStatus(value);

    if (value === "Assigned") {
      setDotFilter({ isAssigned: dotFilter.isAssigned ? null : true });
    } else {
      setDotFilter({
        isAssigned: dotFilter.isAssigned === false ? null : false,
        notAssigned: dotFilter.notAssigned ? null : true
      });
    }
  };

  useEffect(() => {
    const getReport = async () => {
      try {
        await getAllReports(
          {
            isLocked: false,
            from: `${parseInt(0) - 1 < 0 ? 0 : parseInt(0) - 1}`,
            size: 10
          },
          true
        );
      } catch (error) {
        console.error(error);
      }
    };
    getReport();
  }, []);

  useEffect(() => {
    if (resultAllReports?.data?.data) {
      setReportData(resultAllReports?.data?.data?.reportList.find((item) => item._id === reportId));
    }
  }, [resultAllReports?.data?.data]);

  useEffect(() => {
    if (selectedDepartments.length === 0) {
      setSelectedQuestionType(null);
      setSelectedQuestionStatus(null);

      setDotFilter({
        isAssigned: null,
        notAssigned: null
      });
    }
  }, [selectedDepartments]);

  useEffect(() => {
    const section = isSelected === "" ? "ALL" : isSelected;
    const subSectionKey = subSection ? subSection.trim() : "";

    const cleanedObject = Object.keys(dotFilter).reduce((acc, key) => {
      if (dotFilter[key] !== null) {
        acc[key] = dotFilter[key];
      }
      return acc;
    }, {});

    const queryParams = {
      reportId: reportId,
      facility: "ALL",
      section,
      subSection: subSectionKey,
      facilityAssignment: questionType,
      indicator,
      department,
      ...cleanedObject,
      ...(searchTerm ? { search: searchTerm } : {})
    };

    getQuestions(queryParams, true);
    getAssignmentStatus({ reportId: reportId, facility: facFilter, ...cleanedObject }, true);
  }, [isSelected, department, facFilter, page, searchTerm, dotFilter, subSection, questionType, indicator]);

  useEffect(() => {
    setOpenAccordionIndex(null);
  }, [page, isSelected]);

  useEffect(() => {
    getUser({ id: userId._id }, true);
  }, []);

  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data);
    }
  }, [userResult.data?.data]);

  useEffect(() => {
    if (resultQuestions.data) {
      setQuestion(resultQuestions.data?.data?.questions);
    }
  }, [resultQuestions.data?.data]);

  useEffect(() => {
    getUserDepartment({}, true);
  }, []);

  useEffect(() => {
    if (department === "") {
      setIsAllSelected(false);
      setQuestionSelected({});
      setQuestionSelectedDetail([]);
    } else {
      const currentDepartments = department.split(",");

      const filteredQuestionDetail = questionSelectedDetail.filter((question) =>
        currentDepartments.includes(question.department)
      );

      const updatedQuestionSelected = {};
      Object.keys(questionSelected).forEach((questionId) => {
        const questionDetail = questionSelectedDetail.find((q) => q.id === questionId);
        if (questionDetail && currentDepartments.includes(questionDetail.department)) {
          updatedQuestionSelected[questionId] = questionSelected[questionId];
        }
      });

      setQuestionSelected(updatedQuestionSelected);
      setQuestionSelectedDetail(filteredQuestionDetail);
    }
  }, [department]);

  useEffect(() => {
    const currentDepartments = department.split(",");
    const visibleQuestions = question?.filter((q) => currentDepartments.includes(q.department)) || [];

    if (visibleQuestions.length > 0 && questionSelectedDetail.length === visibleQuestions.length) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [questionSelectedDetail.length, question, department]);

  const changeDepartment = async () => {
    let body = {
      questionId: questionId,
      reportId: reportId,
      department: departmentVal
    };
    try {
      const response = await postChangeDepartment(body);
      if (response.error) {
        openSnackbar(response.error.data?.message, "error");
      } else {
        openSnackbar(response.data?.message, "success");
        setShowModal(false);
        // postAuditLogData({
        //   action: "CREATE",
        //   message: `${user.name} created new simulation ${simulationsModalValues.name}`,
        //   tab: "Simulation",
        //   facility: "",
        //   organization: orgData.data?.data._id,
        //   user: userId._id,
        //   ipAddress: "10.10.0.1"
        // });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      openSnackbar(errorMessage, "error");
    }
  };

  const onSearch = (value) => {
    try {
      setSearchTerm(value);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAllQuestionSelected = (e) => {
    let obj = {};
    let arr = [];
    question?.map((item) => {
      let objDetail = {
        id: item._id,
        department: item.department,
        subtitle: item.principle,
        title: item.questionTitle,
        contributors: item.contributors,
        facilityAssignment: item.facilityAssignment
      };

      obj[item._id] = e.target.checked;
      obj = { ...obj };

      if (e.target.checked) {
        arr.push(objDetail);
      } else {
        arr = [];
      }
    });

    setQuestionSelected({ ...questionSelected, ...obj });
    setQuestionSelectedDetail(arr);
    setIsAllSelected(e.target.checked);
  };

  const toggleDrawer = () => {
    setBulkAssignDrawer(!bulkAssignDrawer);
  };

  console.log("department",department)
  console.log("questionType",questionType)
  console.log("reportRole",user?.reportRole)

  return (
    <>
      {showModal && (
        <CustomModal modalOpen={showModal} setModalOpen={setShowModal} outSideClose={false}>
          <div className="d-flex gap-3">
            <div>
              <img src="/images/icons/warning.svg" alt="icon" />
            </div>

            <div style={{ maxWidth: "390px" }}>
              <div>
                <p className="modal-title-plan text-start" style={{ fontSize: "16px" }}>
                  Are you sure you want to change the question's department?
                </p>
                <p className="sub-heading-text" style={{ fontSize: "14px" }}>
                  By changing the department from here, this question will be removed.
                </p>
              </div>

              <div className="d-flex gap-4 mt-3">
                <button type="button" className="btn btn-outline-success" onClick={() => setShowModal(false)}>
                  Cancel
                </button>
                <button type="button" className="btn btn-success" onClick={changeDepartment}>
                  Change
                </button>
              </div>
            </div>

            <div className="close-icon-placement" style={{}} onClick={() => setShowModal(false)}>
              <img src="/images/icons/cross-icon.svg" alt="icon" className="cursor-pointer x-icon" />
            </div>
          </div>
        </CustomModal>
      )}

      {error && Array.isArray(error) && error.length > 0 && (
        <CustomModal modalOpen={error} setModalOpen={setError} outSideClose={false}>
          <div className="d-flex gap-3">
            <div>
              <img src="/images/icons/warning.svg" alt="icon" />
            </div>

            <div style={{ maxWidth: "390px" }}>
              <div>
                <p className="modal-title-plan text-start" style={{ fontSize: "16px" }}>
                  Error
                </p>
                {error && Array.isArray(error) ? (
                  error.map((item) => (
                    <div className="mt-3">
                      <p>
                        <span className="modal-title-plan">Facility:</span> {item.facility}
                      </p>
                      <p className="sub-heading-text">
                        <span className="modal-title-plan">Issue:</span> {item.missingData}
                      </p>
                    </div>
                  ))
                ) : (
                  <div className="mt-3">
                    <p className="sub-heading-text">{error}</p>
                  </div>
                )}
              </div>

              <div className="d-flex gap-4 mt-3">
                <button type="button" className="btn btn-success" onClick={() => setError(null)}>
                  Ok
                </button>
              </div>
            </div>

            <div className="close-icon-placement" onClick={() => setError(null)}>
              <img src="/images/icons/cross-icon.svg" alt="icon" className="cursor-pointer x-icon" />
            </div>
          </div>
        </CustomModal>
      )}

      <div className="assign-questions-wrapper">
        <div className="d-flex justify-content-between align-items-center" style={{ marginBottom: "18px" }}>
          <div className="d-flex" style={{ flexDirection: "column", gap: "5px" }}>
            <p
              className="blue-link-text cursor pointer"
              onClick={() =>
                navigate("/admin", {
                  state: { data: reportData }
                })
              }
            >
              {"< Back"}
            </p>
            <p className="sub-details">BRSR (FY {reportFyYear})</p>
            <p className="main-page-title">Assign Questions (bulk)</p>
          </div>
          {bulkAssignDrawer && (
            <GlobalBulkAssignDrawer
              isOpen={bulkAssignDrawer}
              onClose={toggleDrawer}
              questionData={questionSelectedDetail}
              reportId={reportId}
              department={department}
              facilityAssignment={questionType}
              error={error}
              setError={setError}
              setQuestionSelected={setQuestionSelected}
              setQuestionSelectedDetail={setQuestionSelectedDetail}
            />
          )}
        </div>
        <div className="d-flex gap-2 align-items-center">
          <SearchBar placeHolder={"Search"} onSearch={onSearch} />
          <div className="d-flex gap-2 align-items-center">
            <p className="sub-details">No. of questions assigned from:</p>
            <div
              className="white-pill"
              style={{
                "--fill-width": `${parseInt(
                  (resultAssignmentStatus.data?.data?.find((item) => item._id === "SectionA")?.assignedCount /
                    resultAssignmentStatus.data?.data?.find((item) => item._id === "SectionA")?.totalQuestions) *
                    100
                )}%`,
                "--fill-color": "#38CB2466"
              }}
            >
              Section A:{" "}
              {resultAssignmentStatus.data?.data?.find((item) => item._id === "SectionA")
                ? resultAssignmentStatus.data?.data?.find((item) => item._id === "SectionA")?.assignedCount
                : 0}
            </div>
            <div
              className="white-pill"
              style={{
                "--fill-width": `${parseInt(
                  (resultAssignmentStatus.data?.data?.find((item) => item._id === "SectionB")?.assignedCount /
                    resultAssignmentStatus.data?.data?.find((item) => item._id === "SectionB")?.totalQuestions) *
                    100
                )}%`,
                "--fill-color": "#FFAD0F66"
              }}
            >
              Section B:{" "}
              {resultAssignmentStatus.data?.data?.find((item) => item._id === "SectionB")
                ? resultAssignmentStatus.data?.data?.find((item) => item._id === "SectionB")?.assignedCount
                : 0}
            </div>
            <div
              className="white-pill"
              style={{
                "--fill-width": `${parseInt(
                  (resultAssignmentStatus.data?.data?.find((item) => item._id === "SectionC")?.assignedCount /
                    resultAssignmentStatus.data?.data?.find((item) => item._id === "SectionC")?.totalQuestions) *
                    100
                )}%`,
                "--fill-color": "#FF535B80"
              }}
            >
              Section C:{" "}
              {resultAssignmentStatus.data?.data?.find((item) => item._id === "SectionC")
                ? resultAssignmentStatus.data?.data?.find((item) => item._id === "SectionC")?.assignedCount
                : 0}
            </div>
            <div className="white-pill" style={{ "--fill-width": `${0}%`, "--fill-color": "#FFFFFF", fontWeight: 500 }}>
              Total questions:{" "}
              {`${
                (resultAssignmentStatus.data?.data?.find((item) => item._id === "SectionA")?.assignedCount ?? 0) +
                (resultAssignmentStatus.data?.data?.find((item) => item._id === "SectionB")?.assignedCount ?? 0) +
                (resultAssignmentStatus.data?.data?.find((item) => item._id === "SectionC")?.assignedCount ?? 0)
              } / ${
                (resultAssignmentStatus.data?.data?.find((item) => item._id === "SectionA")?.totalQuestions ?? 0) +
                (resultAssignmentStatus.data?.data?.find((item) => item._id === "SectionB")?.totalQuestions ?? 0) +
                (resultAssignmentStatus.data?.data?.find((item) => item._id === "SectionC")?.totalQuestions ?? 0)
              }
              `}
            </div>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <p className="sub-details">Go to Page</p>
            <CustomSectionDropdown sections={sections} onChange={handleChange} />
          </div>
        </div>
        <div className="d-flex justify-content-between my-3 align-items-center">
          <div className="d-flex gap-2 align-items-center">
            {department !== "" && questionType !== "" && user?.reportRole === "Admin" && (
              <div className="d-flex gap-1 align-items-center" style={{ marginRight: "8px" }}>
                <Checkbox disabled={question?.length === 0} checked={isAllSelected} onChange={(e) => handleAllQuestionSelected(e)} data-testid="select-all" />
                <p style={{ fontSize: "12px", fontWeight: "400", textWrap: "nowrap" }}>Select all</p>
              </div>
            )}
            <Select
              placeholder={"Sort by department"}
              showSearch
              name="department"
              mode="multiple"
              size="small"
              value={selectedDepartments}
              onChange={handleDepartmentChange}
              className="br-8"
              style={{ minWidth: "180px" }}
            >
              <Select.Option value={"ALL"}>All departments</Select.Option>
              {resultDepartment?.data?.data?.map((item, index) => (
                <Select.Option key={index + "i"} value={item.name} disabled={selectedDepartments.includes("ALL")}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
            <Select
              placeholder={"Select questions type"}
              showSearch
              name="questionType"
              size="small"
              value={selectedQuestionType}
              onChange={handleFacilityAssignmentChange}
              className="br-8"
              style={{ minWidth: "150px" }}
              disabled={selectedDepartments.length === 0}
            >
              <Select.Option value={"BOTH"}>Both</Select.Option>
              {facilityAssignmentArr?.map((item, index) => (
                <Select.Option key={index + "i"} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
            <Select
              placeholder={"Select question status"}
              showSearch
              name="questionStatus"
              size="small"
              value={selectedQuestionStatus}
              onChange={handleQuestionStatusChange}
              className="br-8"
              style={{ minWidth: "150px" }}
              disabled={selectedDepartments.length === 0}
            >
              {questionStatusArr?.map((item, index) => (
                <Select.Option key={index + "i"} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="d-flex align-items-center gap-3">
            <div
              className="d-flex gap-2 align-items-center"
              onClick={() =>
                setDotFilter({
                  isAnswered: null,
                  isUnanswered: null,
                  isApproved: null,
                  isRejected: null,
                  isPendingApproval: null,
                  isAccepted: null,
                  notAccepted: null,
                  overdue: null,
                  isAssigned: null,
                  notAssigned: null
                })
              }
            >
              <p className="sub-heading-text">Status:</p>
              <Chip
                label={selectedDepartments.includes("ALL") || !department ? "All questions" : `${department} questions`}
                size="small"
                sx={{ fontSize: "12px", fontWeight: 400 }}
                variant={""}
                color={"primary"}
                isActive={true}
              />
            </div>
            <div className="d-flex gap-2">
              <>
                <div
                  className={`d-flex gap-1 align-items-center cursor-pointer ${dotFilter.isAssigned && "gray-pill"} `}
                  onClick={() => setDotFilter({ isAssigned: dotFilter.isAssigned ? null : true })}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="8" viewBox="0 0 7 8" fill="none">
                    <circle opacity="0.85" cx="3.5" cy="4" r="3.5" fill="#38CB24" />
                  </svg>
                  <p className="caption-1-Regular">Assigned</p>
                </div>
                <div
                  className={`d-flex gap-1 align-items-center cursor-pointer ${
                    dotFilter.isAssigned === false && "gray-pill"
                  } `}
                  onClick={() =>
                    setDotFilter({
                      isAssigned: dotFilter.isAssigned === false ? null : false,
                      notAssigned: dotFilter.notAssigned ? null : true
                    })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="8" viewBox="0 0 7 8" fill="none">
                    <circle opacity="0.85" cx="3.5" cy="4" r="3.5" fill="#E2E2EA" />
                  </svg>
                  <p className="caption-1-Regular" style={{ whiteSpace: "nowrap" }}>
                    Not Assigned
                  </p>
                </div>
                <div
                  className={`d-flex gap-1 align-items-center cursor-pointer ${dotFilter.overdue && "gray-pill"} `}
                  onClick={() => setDotFilter({ overdue: dotFilter.overdue ? null : true })}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="8" viewBox="0 0 7 8" fill="none">
                    <circle opacity="0.85" cx="3.5" cy="4" r="3.5" fill="#FF535B" />
                  </svg>
                  <p className="caption-1-Regular">Not Assigned (Overdue)</p>
                </div>
              </>
            </div>
          </div>
        </div>

        <>
          {resultQuestions.isLoading || resultQuestions.isFetching ? (
            <div className="d-flex h-100 justify-content-center align-items-center">
              <WidgetLoader />
            </div>
          ) : (
            <>
              {department !== "" && questionType !== "" && (
                <div className="assign-contributor">
                  <p className="sub-heading-text">
                    {questionSelectedDetail.length === question?.length
                      ? `All ${question?.length} questions on this page are selected.`
                      : `${questionSelectedDetail.length} question(s) has been selected`}
                  </p>
                  <BRSRButton
                    button
                    type="button"
                    onClick={toggleDrawer}
                    questionSelected={questionSelectedDetail.length > 0 ? false : true}
                  >
                    {"Assign to contributor >"}
                  </BRSRButton>
                </div>
              )}
              <div>
                {question?.length > 0 ? (
                  <div style={{ height: "calc(100vh - 250px)" }}>
                    <Virtuoso
                      style={{ height: "100%" }}
                      totalCount={question.length}
                      overscan={500}
                      components={{
                        Scroller: React.forwardRef((props, ref) => (
                          <div
                            {...props}
                            ref={ref}
                            style={{
                              ...props.style,
                              scrollBehavior: "smooth",
                              WebkitOverflowScrolling: "touch",
                              scrollbarWidth: "thin",
                              msOverflowStyle: "none",
                              "&::-webkit-scrollbar": {
                                width: "8px"
                              },
                              "&::-webkit-scrollbar-track": {
                                background: "#f1f1f1"
                              },
                              "&::-webkit-scrollbar-thumb": {
                                background: "#888",
                                borderRadius: "4px"
                              }
                            }}
                          />
                        ))
                      }}
                      itemContent={(index) => {
                        const item = question[index];
                        return (
                          <div>
                            <BRSRAccordion
                              key={item._id}
                              userFac={user}
                              title={item.questionTitle}
                              facilityAssignment={item.facilityAssignment}
                              indicator={item.indicator}
                              subtitle={item.principle}
                              isOpen={openAccordionIndex === index}
                              onToggle={undefined}
                              department={item.department}
                              questionType={item.questionType}
                              contributors={resultContributors.data?.data}
                              assignedContributors={item.contributors}
                              isAssigned={item.isAssigned}
                              questionId={questionId}
                              _questionId={item._id}
                              reportId={reportId}
                              assignQuestion={assignQuestion}
                              openSnackbar={openSnackbar}
                              facFilter={facFilter}
                              setFacFilter={setFacFilter}
                              resultDepartment={resultDepartment}
                              setShowModal={setShowModal}
                              setDepartmentVal={setDepartmentVal}
                              // setError={setError}
                              index={index}
                              showCheckbox={department === "" ? false : true}
                              questionSelected={questionSelected}
                              questionSelectedDetail={questionSelectedDetail}
                              setQuestionSelected={setQuestionSelected}
                              setQuestionSelectedDetail={setQuestionSelectedDetail}
                              navigate={navigate}
                              section={item.section}
                              colors={colors}
                              setQuestionId={setQuestionId}
                              wholeQuestion={item}
                              assignedQuest={false}
                              principle={item.principle}
                              reportFyYear={reportFyYear}
                              isGlobalAssignBulk={true}
                            />
                          </div>
                        );
                      }}
                    />
                  </div>
                ) : (
                  <EmptyState dotFilter={dotFilter} role={user?.reportRole} />
                )}
              </div>
            </>
          )}
        </>
      </div>
    </>
  );
};

export default GlobalAssignQuestionsBulk;
