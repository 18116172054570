import React from 'react'
import { useNavigate } from 'react-router-dom'
import './ActionCard.scss'

const ActionCard = ({ title, description, tags, item, openActionModal, logo }) => {
  const navigate = useNavigate()
  return (
    <div className={`main-card-container-action active-background`}>
      <div className='h-50'>
        <img src={logo ? logo : 'https://purebluesustainability.com/wp-content/uploads/2022/02/Environmental-Sustainability-Examples-Tips-to-Implement-Them.jpg'} height={'100%'} width={'100%'} alt="image" />
      </div>
      <div className='card-body'>
        <div className='info-container-action'>
          <div>
            <p className='label'>{title}</p>
            <p className='sub-label'>{description}</p>
          </div>
          <div className='tag-label'>
            <>
              {tags.map((tag) => (
                <p>#{tag}</p>
              ))}
            </>
          </div>
          <div className='button-container'>
            <button className="btn btn-outline-success w-40" onClick={() => navigate('/action/cataloguedetail', { state: { templateId: item._id }})}>Read More</button>
            <button className="btn btn-outline-success w-40" onClick={() => openActionModal(item._id)}>+ Add Solution</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActionCard